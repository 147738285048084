import * as React from "react";
import { DataTable } from "../../../../components/others/data/DataTable";
import { Table, Button, Tooltip, Typography } from "antd";
import { displayIconStatusWithTooltip } from "../../../../helpers/IconsGeneratorHelper";
import {
  findInDictionaryAndReturnValue,
  getDictionary,
} from "../../../../helpers/DisctionariesHelper";
import { convertBooleanYesOrNoReactElement } from "../../../../helpers/BooleanHelper";
import { resources } from "../../../../common/Resources";
import { spanWithTitle } from "../../../../components/others/SpanWithTitle";
import { ProtectedLink } from "../../../../components/authorizations/protectedComponents/ProtectedLink";
import { UserPermissionType } from "../../../../models/IAppUserInfoModel";
import {
  getAvailableInAffbayFilters,
  getActivityFilters,
  getAvailableForSaleFilters,
  getVisibleFilters,
} from "../../../../common/dictionaries/Filters";

const { Paragraph } = Typography;
const { Column } = Table;

export class ProductsTable extends DataTable {
  returnTranslatedList = (values) => {
    const elements = values.split(",");
    let categories = "";

    elements.map((item) => {
      if (categories === "") {
        categories = findInDictionaryAndReturnValue(
          Number(item),
          getDictionary("categoriesD")
        );
      } else {
        categories =
          categories +
          ", " +
          findInDictionaryAndReturnValue(
            Number(item),
            getDictionary("categoriesD")
          );
      }

      return null;
    });

    return categories ? categories : "";
  };

  render() {
    const columns = [
      <Column
        title={resources.columnTitle.id}
        dataIndex="id"
        key="id"
        align="center"
        width={70}
        sorter={(a, b) => a.id - b.id}
        onFilter={(value, record) => record.id === Number(value)}
        {...this.getColumnSearchProps("id")}
      />,
      <Column
        title={resources.columnTitle.name}
        dataIndex="name"
        key="name"
        align="center"
        width={250}
        sorter={(a, b) =>
          (a.name ? a.name : "").localeCompare(b.name ? b.name : "")
        }
        {...this.getColumnSearchProps("name")}
      />,
      <Column
        title={resources.columnTitle.country}
        key="country_id"
        align="center"
        width={130}
        sorter={(a, b) => a.country_id - b.country_id}
        render={(record) =>
          findInDictionaryAndReturnValue(
            Number(record.country_id),
            this.props.countries
          )
        }
      />,
      <Column
        title={spanWithTitle(
          resources.columnTitle.avalable_in_affbay,
          resources.columnTitle.avalable_in_affbay_short
        )}
        key="is_visible_affbay"
        align="center"
        width={110}
        sorter={(a, b) => a.is_visible_affbay - b.is_visible_affbay}
        filters={getAvailableInAffbayFilters()}
        onFilter={(value, record) => record.is_visible_affbay === value}
        render={(record) =>
          convertBooleanYesOrNoReactElement(record.is_visible_affbay)
        }
      />,
      <Column
        title={spanWithTitle(
          resources.columnTitle.is_active,
          resources.columnTitle.is_active
        )}
        key="is_active"
        align="center"
        width={95}
        sorter={(a, b) => a.is_active - b.is_active}
        filters={getActivityFilters()}
        onFilter={(value, record) => record.is_active === value}
        render={(record) => displayIconStatusWithTooltip(record.is_active)}
      />,
      <Column
        title={spanWithTitle(
          resources.columnTitle.avalable_for_sale,
          resources.columnTitle.avalable_for_sale_short
        )}
        key="is_available_sale"
        align="center"
        width={100}
        sorter={(a, b) => a.is_available_sale - b.is_available_sale}
        filters={getAvailableForSaleFilters()}
        onFilter={(value, record) => record.is_available_sale === value}
        render={(record) =>
          convertBooleanYesOrNoReactElement(record.is_available_sale)
        }
      />,
      <Column
        title={spanWithTitle(
          resources.columnTitle.base_price,
          resources.columnTitle.base_price_short
        )}
        key="default_price"
        align="center"
        width={85}
        sorter={(a, b) => a.default_price - b.default_price}
        {...this.getColumnSearchProps("default_price")}
        render={(record) =>
          record.is_available_sale
            ? (Number(record.default_price) / 100).toFixed(2)
            : ""
        }
      />,
      <Column
        title="Production cost"
        key="production_cost_local"
        align="center"
        width={85}
        sorter={(a, b) => a.production_cost_local - b.production_cost_local}
        render={(record) => Number(record.production_cost_local / 100)}
      />,
      <Column
        title={spanWithTitle(
          resources.columnTitle.visible_for_partners,
          resources.columnTitle.visible_for_partners_short
        )}
        key="is_visible_partners"
        align="center"
        width={130}
        sorter={(a, b) => a.is_visible_partners - b.is_visible_partners}
        filters={getVisibleFilters()}
        onFilter={(value, record) => record.is_visible_partners === value}
        render={(record) =>
          convertBooleanYesOrNoReactElement(record.is_visible_partners)
        }
      />,
      <Column
        title={resources.columnTitle.categories}
        key="categories"
        align="center"
        width={150}
        render={(record) =>
          record.categories && this.returnTranslatedList(record.categories)
        }
      />,
      <Column
        title={spanWithTitle(
          resources.columnTitle.product_sku,
          resources.columnTitle.product_sku_short
        )}
        key="product_sku"
        align="center"
        width={250}
        {...this.getColumnSearchProps("product_sku")}
        sorter={(a, b) =>
          (a.product_sku ? a.product_sku : "").localeCompare(
            b.product_sku ? b.product_sku : ""
          )
        }
        render={(record) => (
          <Tooltip
            title={
              <Paragraph
                style={{ color: "white", textAlign: "center" }}
                copyable
              >
                {record.product_sku}
              </Paragraph>
            }
          >
            <label className="marginLeft">
              {record.product_sku !== null && record.product_sku.length > 28
                ? record.product_sku.substring(0, 25) + "..."
                : record.product_sku}
            </label>
          </Tooltip>
        )}
      />,
      <Column
        title={resources.columnTitle.options}
        key="options"
        width={60}
        align="center"
        fixed="right"
        render={(record) => (
          <ProtectedLink
            permission={UserPermissionType.EditProductLinkButton}
            to={"product_details/" + record.id}
          >
            <Button icon="arrow-right" />
          </ProtectedLink>
        )}
      />,
    ];

    let sortedData = this.props.data
      ? this.props.data.sort((a, b) => a.name.localeCompare(b.name))
      : [];

    return (
      <DataTable
        tableName="ProductsTable"
        columns={columns}
        data={sortedData}
        loading={this.props.loading}
        pagination={true}
        defaultPageSize={100}
      />
    );
  }
}
